<template>
	<div>
		<div class="main-filter">
			<el-form inline :model="formInline" class="demo-form-inline">
				<el-form-item label="省：">
					<el-select
						v-model="formInline.economize"
						:disabled="userInfo.regionCode && userInfo.regionCode.length >= 2"
						placeholder="请选择省"
						@change="changeEconomize($event, 1)"
					>
						<el-option v-for="item in economizeList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="市：">
					<el-select
						v-model="formInline.market"
						:disabled="userInfo.regionCode && userInfo.regionCode.length >= 4"
						placeholder="请选择市"
						@change="changeEconomize($event, 2)"
					>
						<el-option v-for="item in marketList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="区县：">
					<el-select
						v-model="formInline.district"
						:disabled="userInfo.regionCode && userInfo.regionCode.length >= 6"
						placeholder="请选择区县"
						@change="changeEconomize($event, 3)"
					>
						<el-option v-for="item in districtList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="乡镇：" v-if="checkTab != 'H'">
					<el-select
						v-model="formInline.townCode"
						:disabled="userInfo.regionCode && userInfo.regionCode.length >= 9"
						placeholder="请选择"
						@change="changeEconomize($event, 4)"
					>
						<el-option v-for="item in townList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="等级：" v-else>
					<el-select v-model="formInline.levelDictionaryItemId" placeholder="请选择">
						<el-option v-for="item in levelDictionaryItemList" :key="item.id" :label="item.title" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSearch" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="main-content">
			<div class="tabClass">
				<span v-for="(item, index) in tabList" :key="index" :class="{ activeClass: checkTab == item.code }" @click="changeCheck(item.code)">
					{{ item.name }}
				</span>
			</div>
			<div class="main-content-title">
				<p>共有 {{ total }} 条结果</p>
			</div>
			<Table
				:loading="loading"
				:table-data="tableData"
				:total="total"
				:table-head="checkTab == 'H' ? tableHeadCheck1 : checkTab == 'C' ? tableHeadCheck2 : tableHeadCheck3"
				:current-page.sync="queryData.current"
				:page-size.sync="queryData.size"
				:is-show-selection="false"
				:showOperation="false"
				@change-page="getList"
				:tableHeight="tableHeight"
			>
				<template #account="{ scope }">
					<el-button @click="accountInfoHand(scope.row)" size="mini" type="text"> 账号信息 </el-button>
				</template>
			</Table>
		</div>
		<AccountInfo ref="accountInfo" :isShowDetail="true"></AccountInfo>
	</div>
</template>

<script>
export default {
	name: 'institutionalStatistics',
	components: {
		Table: () => import('@/components/Table/table'),
		AccountInfo: () => import('@/views/staticTotalView/components/newDialog.vue'),
	},
	data() {
		return {
			formInline: {
				economize: '',
				market: '',
				district: '',
				townCode: '',
				levelDictionaryItemId: '',
			},
			tabList: [
				{
					name: '医院',
					code: 'H',
				},
				{
					name: '乡镇卫生院',
					code: 'C',
				},
				{
					name: '村卫生室',
					code: 'R',
				},
				{
					name: '社区卫生中心',
					code: 'L',
				},
				{
					name: '诊所',
					code: 'S',
				},
			],
			tableHeadCheck1: [
				// {
				// 	label: '所属县域账号',
				// 	prop: 'regionManagementGroup',
				// 	formatter: (row) => {
				// 		return row.regionManagementGroup?.title || '-';
				// 	},
				// },
				{
					label: '医疗机构代码',
					prop: 'organizationCode',
					formatter: (row) => {
						return row.organizationCode || '-';
					},
				},
				{
					label: '医疗机构名称',
					prop: 'name',
					formatter: (row) => {
						return row.name || '-';
					},
				},
				{
					label: '所属地市',
					prop: 'regions',
					formatter: (row) => {
						return row.regions && row.regions.length > 0 && row.regions[1] ? row.regions[1].title : '-';
					},
				},
				{
					label: '所属区县',
					prop: 'regions',
					formatter: (row) => {
						return row.regions && row.regions.length > 0 && row.regions[2] ? row.regions[2].title : '-';
					},
				},
				{
					label: '等级',
					prop: 'levelDictionaryItem',
					formatter: (row) => {
						return row.levelDictionaryItem?.title || '-';
					},
				},
				{
					label: '所有制形式',
					prop: 'ownershipDictionaryItem',
					formatter: (row) => {
						return row.ownershipDictionaryItem?.title || '-';
					},
				},
				{
					label: '性质',
					prop: 'characteristic',
					formatter: (row) => {
						return row.characteristic || '-';
					},
				},
				{
					label: '联系人',
					prop: 'principalName',
					formatter: (row) => {
						return row.principalName || '-';
					},
				},
				{
					label: '联系电话',
					prop: 'phoneNumber',
					formatter: (row) => {
						return row.phoneNumber || '-';
					},
				},
				{
					label: '所属账号',
					prop: 'account',
				},
			],
			tableHeadCheck2: [
				{
					label: '机构代码',
					prop: 'organizationCode',
					formatter: (row) => {
						return row.organizationCode || '-';
					},
				},
				{
					label: '卫生院名称',
					prop: 'name',
					formatter: (row) => {
						return row.name || '-';
					},
				},
				{
					label: '所属区县',
					prop: 'regions',
					formatter: (row) => {
						return row.regions && row.regions.length > 0 && row.regions[2] ? row.regions[2].title : '-';
					},
				},
				{
					label: '所属乡镇/街道',
					prop: 'regions',
					formatter: (row) => {
						return row.regions && row.regions.length > 0 && row.regions[3] ? row.regions[3].title : '-';
					},
				},
				{
					label: '机构类别',
					prop: 'discriminationCode',
					formatter: (row) => {
						return row.discriminationCode == 'H'
							? '医院'
							: row.discriminationCode == 'C'
							? '乡镇/街道卫生院'
							: row.discriminationCode == 'R'
							? '村卫生室'
							: row.discriminationCode == 'L'
							? '社区卫生中心'
							: row.discriminationCode == 'S'
							? '诊所'
							: '-';
					},
				},
				{
					label: '联系人',
					prop: 'principalName',
					formatter: (row) => {
						return row.principalName || '-';
					},
				},
				{
					label: '联系电话',
					prop: 'phoneNumber',
					formatter: (row) => {
						return row.phoneNumber || '-';
					},
				},
				{
					label: '所属账号',
					prop: 'account',
				},
			],
			tableHeadCheck3: [
				{
					label: '机构代码',
					prop: 'organizationCode',
					formatter: (row) => {
						return row.organizationCode || '-';
					},
				},
				{
					label: '卫生室名称',
					prop: 'name',
					formatter: (row) => {
						return row.name || '-';
					},
				},
				{
					label: '所属区县',
					prop: 'regions',
					formatter: (row) => {
						return row && row.regions && row.regions.length > 0 && row.regions[2] ? row.regions[2].title : '-';
					},
				},
				{
					label: '所属乡镇/街道',
					prop: 'regions',
					formatter: (row) => {
						return row && row.regions && row.regions.length > 0 && row.regions[3] ? row.regions[3].title : '-';
					},
				},
				{
					label: '所属村',
					prop: 'regions',
					formatter: (row) => {
						return row && row.regions && row.regions.length > 0 && row.regions[4] ? row.regions[4].title : '-';
					},
				},
				{
					label: '机构类别',
					prop: 'discriminationCode',
					formatter: (row) => {
						return row.discriminationCode == 'H'
							? '医院'
							: row.discriminationCode == 'C'
							? '乡镇/街道卫生院'
							: row.discriminationCode == 'R'
							? '村卫生室'
							: row.discriminationCode == 'L'
							? '社区卫生中心'
							: row.discriminationCode == 'S'
							? '诊所'
							: '-';
					},
				},
				{
					label: '联系人',
					prop: 'principalName',
					formatter: (row) => {
						return row.principalName || '-';
					},
				},
				{
					label: '联系电话',
					prop: 'phoneNumber',
					formatter: (row) => {
						return row.phoneNumber || '-';
					},
				},
				{
					label: '所属账号',
					prop: 'account',
				},
			],
			checkTab: null,
			loading: false,
			queryData: {
				current: 1,
				size: 10,
			},
			total: 0,
			tableData: [],
			districtList: [], //区县
			marketList: [], //市
			townList: [],
			economizeList: [], //省
			options: [],
			dictionaryList: [],
			levelDictionaryItemList: [],
			userInfo: {},
		};
	},
	computed: {
		tableHeight() {
			return window.innerHeight - 300;
		},
	},
	watch: {},
	mounted() {
		this.checkTab = this.tabList[0].code;
		// this.getInstitTypeList();
		this.getEconomizeList();
		this.userInfo = JSON.parse(sessionStorage.getItem('userInfo')) || {};
		switch (this.userInfo.regionCode.length) {
			case 2:
				this.formInline.economize = this.userInfo.chinaRegionDtoList[0].code != '0' ? this.userInfo.chinaRegionDtoList[0].code : null;
				this.changeEconomize(this.formInline.economize, 1);
				break;
			case 4:
				this.formInline.economize = this.userInfo.chinaRegionDtoList[0].code != '0' ? this.userInfo.chinaRegionDtoList[0].code : null;
				this.changeEconomize(this.formInline.economize, 1);
				this.formInline.market = this.userInfo.chinaRegionDtoList[1].code;
				this.changeEconomize(this.formInline.market, 2);
				break;
			case 6:
				this.formInline.economize = this.userInfo.chinaRegionDtoList[0].code != '0' ? this.userInfo.chinaRegionDtoList[0].code : null;
				this.changeEconomize(this.formInline.economize, 1);
				this.formInline.market = this.userInfo.chinaRegionDtoList[1].code;
				this.changeEconomize(this.formInline.market, 2);
				this.formInline.district = this.userInfo.chinaRegionDtoList[2].code;
				this.changeEconomize(this.formInline.district, 3);
				break;
			case 9:
				this.formInline.economize = this.userInfo.chinaRegionDtoList[0].code != '0' ? this.userInfo.chinaRegionDtoList[0].code : null;
				this.changeEconomize(this.formInline.economize, 1);
				this.formInline.market = this.userInfo.chinaRegionDtoList[1].code;
				this.changeEconomize(this.formInline.market, 2);
				this.formInline.district = this.userInfo.chinaRegionDtoList[2].code;
				this.changeEconomize(this.formInline.district, 3);
				this.formInline.townCode = this.userInfo.chinaRegionDtoList[3].code;
				break;

			default:
				break;
		}
		this.getList();
	},
	methods: {
		// 获取机构等级list
		getInstitList() {
			this.$http
				.get(this.api['DictionaryItems#index'].href, { params: { dictionaryCode: 'INSTITUTIONAL_LEVEL' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.levelDictionaryItemList = res.data.collection || [];
						this.levelDictionaryItemList.unshift({
							id: null,
							title: '全部',
						});
					}
				})
				.catch((e) => {});
		},
		// 获取机构类别list
		getInstitTypeList() {
			this.$http
				.get(this.api['DictionaryItems#index'].href, { params: { dictionaryCode: 'INSTITUTIONAL_TYPE' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.dictionaryList = res.data.collection || [];
						this.dictionaryList.unshift({
							id: null,
							title: '全部',
						});
					}
				})
				.catch((e) => {});
		},
		// 获取省数据
		getEconomizeList() {
			this.$http
				.get(this.api['ChinaRegions#index'].href)
				.then((res) => {
					if (res.data && res.data.success) {
						this.economizeList = res.data.collection || [];
						this.economizeList.unshift({
							title: '全部',
							code: '',
						});
					}
				})
				.catch((e) => {});
		},
		// 获取市/区县
		changeEconomize(data, type) {
			switch (type) {
				case 1:
					this.formInline.market = null;
					this.formInline.district = null;
					this.formInline.townCode = null;
					this.marketList = [];
					this.districtList = [];
					this.townList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api['ChinaRegions#index'].href, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.marketList = res.data.collection || [];
								this.marketList.unshift({
									title: '全部',
									code: '',
								});
							}
						})
						.catch((e) => {});
					break;
				case 2:
					this.formInline.district = null;
					this.formInline.townCode = null;
					this.districtList = [];
					this.townList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api['ChinaRegions#index'].href, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.districtList = res.data.collection || [];
								this.districtList.unshift({
									title: '全部',
									code: '',
								});
							}
						})
						.catch((e) => {});
					break;
				case 3:
					this.formInline.townCode = null;
					this.townList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api['ChinaRegions#index'].href, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.townList = res.data.collection || [];
								this.townList.unshift({
									title: '全部',
									code: '',
								});
							}
						})
						.catch((e) => {});
					break;
				default:
					break;
			}
		},
		// 查询条件
		onSearch() {
			this.queryData.current = 1;
			this.getList('');
		},
		// 搜索建议
		querySearchAsync(queryString, cb) {
			queryString ? this.getList('filter') : '';
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				cb(this.options);
			}, 1000 * Math.random());
		},
		//获取列表
		getList(type = '') {
			type == 'filter' ? '' : (this.loading = true);
			const { economize, market, district, townCode, levelDictionaryItemId } = this.formInline;
			let code =
				economize && !market && !district && !townCode
					? economize
					: economize && market && !district && !townCode
					? market
					: economize && market && district && !townCode
					? district
					: economize && market && district && townCode
					? townCode
					: null;
			let data = {
				regionCode: code,
				levelDictionaryItemId: levelDictionaryItemId,
				...this.queryData,
				discriminationCode: this.checkTab,
			};

			this.$http
				.get(this.api['MedicalOrganizations#index'].href, { params: data })
				.then((res) => {
					if (res.data && res.data.success) {
						if (type == 'filter') {
							this.options = res.data.collection.map((item) => {
								return {
									...item,
									value: item.regionManagementGroup.title,
								};
							});
						} else {
							this.tableData = res.data.collection || [];
							this.$forceUpdate();
							this.total = res.data.pagination.totalItems || 0;
							this.loading = false;
						}
					}
				})
				.catch((e) => {
					this.loading = false;
				});
		},
		accountInfoHand(row) {
			if (row.regionAccount?.id) {
				let data = {
					...row.regionAccount,
					chinaRegionDtoList: row.regions,
				};
				this.$refs.accountInfo.init(data);
			}
		},
		changeCheck(val) {
			this.checkTab = val;
			this.getList();
		},
	},
};
</script>

<style lang="scss" scoped>
.demo-form-inline {
	/deep/ .el-select {
		width: 120px !important;
		.el-input {
			width: 120px !important;
			.el-input__inner {
				width: 120px !important;
			}
		}
	}
	/deep/ .el-input {
		width: 200px;
	}
}
.main-content {
	.tabClass {
		width: calc(100% + 32px);
		line-height: 48px;
		border-bottom: 1px solid rgba($color: #000, $alpha: 0.1);
		margin-left: -16px;
		padding: 0 16px;
		span {
			display: inline-block;
			line-height: 48px;
			margin-right: 32px;
			font-size: 16px;
			cursor: pointer;
			color: #999999;
			&:hover {
				color: #1db9b1;
				border-bottom: 2px solid #1db9b1;
			}
		}
		.activeClass {
			color: #1db9b1;
			border-bottom: 2px solid #1db9b1;
		}
	}
	&-title {
		line-height: 48px;
		display: flex;
		justify-content: space-between;
		.el-button {
			line-height: 32px;
			padding: 0 16px;
			height: 32px;
			margin-top: 8px;
		}
	}
}
</style>